/* eslint-disable no-tabs */
import { useMutation, useQuery } from 'react-query';
import { ethers } from 'ethers';

import { notify } from 'src/utils/notify';

import useAPICaller from '../useAPICaller';

export async function getXellarData(
	contractAddress: string,
	contractAbi: any,
	userAddress: string,
	funcName: string,
	...params: any[]
) {
	try {
		// Initialize contract instance with the ABI and address
		const contract = new ethers.Contract(contractAddress, contractAbi);

		// Encode the function call
		const data = contract.interface.encodeFunctionData(funcName, params);

		// Construct the transaction object
		const transaction = {
			data: { from: userAddress, to: contractAddress, data: data },
			status: true,
		};

		return transaction;
	} catch (error) {
		console.log(error);
		return {
			data: { from: null, to: null, data: null },
			status: false,
		};
	}
}

export async function getReturnValues(
	provider: any,
	contractAddress: any,
	contractAbi: any,
	hash: string,
	eventName: string,
) {
	return new Promise((resolve, reject) => {
		const tryFetchReceipt = async (attempts: number = 20) => {
			try {
				const receipt = await provider.getTransactionReceipt(hash);
				if (receipt) {
					// Initialize the contract interface to parse events
					const contractInterface = new ethers.utils.Interface(contractAbi);

					// Filter logs to get only those from the specific contract address
					const contractLogs = receipt?.logs?.filter(
						(log: any) => log.address.toLowerCase() === contractAddress.toLowerCase(),
					);

					// Parse each log
					const events = contractLogs
						?.map((log: any) => {
							try {
								return contractInterface.parseLog(log);
							} catch (error) {
								console.warn('Failed to parse log:', log, error);
								return null; // Ignore logs that don't match the ABI
							}
						})
						?.filter((event: any) => event !== null); // Filter out null entries

					const returnValues = events?.find(({ name }: any) => name === eventName)?.args;
					resolve(returnValues);
				} else if (attempts > 0) {
					// If the receipt is not found, retry after 3 seconds
					setTimeout(() => tryFetchReceipt(attempts - 1), 3000);
				} else {
					reject('Transaction receipt not found after multiple attempts');
				}
			} catch (error) {
				reject(error);
			}
		};

		// Start fetching receipt with 5 attempts
		tryFetchReceipt();
	});
}

// // Example usage
// const contractAddress = '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce820';
// const contractAbi = [
// 	{
// 		constant: false,
// 		inputs: [
// 			{
// 				name: '_spender',
// 				type: 'address',
// 			},
// 			{
// 				name: '_value',
// 				type: 'uint256',
// 			},
// 		],
// 		name: 'approve',
// 		outputs: [
// 			{
// 				name: '',
// 				type: 'bool',
// 			},
// 		],
// 		type: 'function',
// 	},
// ];

// const userAddress = '0x5B3A69CD0984c8621c75c54aa4dCA89e382523A9';
// const spenderAddress = '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce820';
// const amount = 100;

// getXellarData(contractAddress, contractAbi, userAddress, 'approve', spenderAddress, amount)
// 	.then((transaction) => {
// 		console.log('Transaction:', transaction);
// 	})
// 	.catch((error) => {
// 		console.error('Error:', error);
// 	});

const queryKeyGetXellarBalance = 'queryKeyGetXellarBalance';
const queryKeyGetXellarChains = 'queryKeyGetXellarChains';

export const useGetXellarBalanceList = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyGetXellarBalance],
		() => {
			return fetchAPI({
				endpoint: '/xellar/tokens',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetXellarChains = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyGetXellarChains],
		() => {
			return fetchAPI({
				endpoint: '/xellar/chains',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useXellarUpdateChain = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { chain_id: number }) =>
			fetchAPI({
				endpoint: '/xellar/current-chain',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ??
						err?.response?.data?.message ??
						err?.message ??
						'Cannot update chain',
					'error',
				);
			},
		},
	);
};

export const useXellarEstimateGas = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { chain_id: number; transaction: any }) =>
			fetchAPI({
				endpoint: '/xellar/estimate-gas',
				method: 'POST',
				data: { ...payload },
			}),
		// {
		// 	onSuccess: () => {},
		// 	onError: (err: any) => {
		// 		notify(
		// 			err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
		// 			'error',
		// 		);
		// 	},
		// },
	);
};

export const useXellarTransfer = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { to: string; amount: string; tokenAddress: string }) =>
			fetchAPI({
				endpoint: '/xellar/transfer',
				method: 'POST',
				data: payload,
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(
					err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
					'error',
				);
			},
		},
	);
};

export const useXellarSendTransaction = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(payload: { chain_id: number; transaction: any }) =>
			fetchAPI({
				endpoint: '/xellar/send-transaction',
				method: 'POST',
				data: payload,
			}),
		// {
		// onSuccess: () => {},
		// onError: (err: any) => {
		// notify(
		// err?.response?.data?.data?.error ?? err?.response?.data?.message ?? err?.message,
		// 'error',
		// );
		// },
		// },
	);
};
