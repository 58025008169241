import { useEffect, useState } from 'react';

import ModalC from 'src/components/Modal';
import { useWeb3Context } from 'src/context/web3';
import useXellarReducer from 'src/hooks/reducers/useXellarReducer';
import { useGetBnbData } from 'src/hooks/useGeneral';
import { useXellarEstimateGas, useXellarSendTransaction } from 'src/hooks/xellar/useXellar';
import convertBigNumber from 'src/utils/func/convert-bignumber';
import { notify } from 'src/utils/notify';

import CardAllowance from '../CardAllowance';
import CardTransaction from '../CardTransaction';

import classes from './index.module.less';

const PopupConfirmation = () => {
	const {
		xellarPopupConfirmationOpen,
		xellarPopupConfirmationType,
		setXellarPopupConfirmationResult,
		xellarAllowanceData,
		xellarTransactionData,
	} = useXellarReducer();

	const { xellarChainId, xellarCurrentChain } = useWeb3Context();

	const [loading, setLoading] = useState<boolean>(false);

	const {
		mutate: estimateGasFee,
		isLoading: isLoadingEstimateGasFee,
		isError: isErrorEstimateGasFee,
		error: errorEstimateGasFee,
		data: dataGasFee,
	} = useXellarEstimateGas();
	const estimateGas = dataGasFee?.data?.data?.estimateGas;

	const gasPrice = estimateGas?.gasPrice || '0';
	const gasLimit = estimateGas?.gasLimit || '0';

	const gasFeeToken = gasPrice && gasLimit ? Number(gasPrice) * Number(gasLimit) : 0;

	const balanceCoin = dataGasFee?.data?.data?.balanceCoin;
	const balanceToken = Number(balanceCoin?.balance || 0) * 10 ** 18;

	const { data: dataBnb, isFetching: isLoadingBnb } = useGetBnbData(estimateGas !== undefined);

	const currentChainId = Number(xellarChainId || 0);
	const rateUsd = dataBnb?.data?.[currentChainId === 1 ? 'ethereum' : 'binancecoin']?.usd || 0;

	const gasFeeUsd = parseFloat((convertBigNumber(gasFeeToken, 8) * rateUsd).toFixed(8));
	const gasFee = convertBigNumber(gasFeeToken, 8);
	const coinCode = xellarCurrentChain?.coin_code;

	const isLoadingEstimateGas = isLoadingEstimateGasFee || isLoadingBnb;

	const { mutate: xellarSend } = useXellarSendTransaction();

	// const loading = isLoading;

	const dataEstimateGas = {
		loading: isLoadingEstimateGas,
		error: isErrorEstimateGasFee,
		enoughBalance:
			JSON.stringify((errorEstimateGasFee as any)?.response?.data ?? null)
				?.toLowerCase()
				?.indexOf('transfer amount exceeds balance') > -1
				? false
				: balanceToken < gasFeeToken
					? false
					: true,
		usd: estimateGas ? gasFeeUsd : undefined,
		coin: estimateGas ? gasFee : undefined,
		coin_code: estimateGas ? coinCode : undefined,
	};

	useEffect(() => {
		if (xellarPopupConfirmationOpen) {
			estimateGasFee(
				{
					chain_id: parseInt(xellarChainId),
					transaction:
						xellarPopupConfirmationType === 'allowance'
							? xellarAllowanceData
							: xellarTransactionData,
				},
				{
					onSuccess: (res: any) => {
						// console.log(res);

						if (res.status === 200) {
							// console.log(res?.data?.data?.estimateGas);
						} else {
						}
					},
					onError: (err: any) => {
						console.error(err);
					},
				},
			);
		}
	}, [xellarPopupConfirmationOpen, xellarPopupConfirmationType]);

	const onReject = () => {
		if (xellarPopupConfirmationType === 'allowance') {
			setXellarPopupConfirmationResult({
				status: false,
				message: 'User rejected approval',
				data: 'reject',
			});
		} else {
			setXellarPopupConfirmationResult({
				status: false,
				message: 'User rejected transaction',
				data: 'reject',
			});
		}
	};

	const onConfirm = () => {
		setXellarPopupConfirmationResult(null);

		setLoading(true);

		xellarSend(
			{
				chain_id: parseInt(xellarChainId),
				transaction:
					xellarPopupConfirmationType === 'allowance' ? xellarAllowanceData : xellarTransactionData,
			},
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						if (xellarPopupConfirmationType === 'allowance') {
							setTimeout(() => {
								setXellarPopupConfirmationResult({
									status: true,
									message: null,
									data: res?.data?.data,
								});
								setLoading(false);
							}, 5000);
						} else {
							setXellarPopupConfirmationResult({
								status: true,
								message: null,
								data: res?.data?.data,
							});
							setLoading(false);
						}
					} else {
						setXellarPopupConfirmationResult({
							status: false,
							message: JSON.stringify(res?.data?.data?.error),
							data: null,
						});
						notify('Transaction failed. Something went wrong.', 'error');
						setLoading(false);
					}
				},
				onError: (err: any) => {
					console.error(err);

					setXellarPopupConfirmationResult({
						status: false,
						message: err?.message || 'Something went wrong',
						data: null,
					});
					notify('Transaction failed. Something went wrong.', 'error');
					setLoading(false);
				},
			},
		);
	};

	return (
		<ModalC
			className={classes.modal}
			open={xellarPopupConfirmationOpen}
			centered
			showCloseIcon={false}
			closeOnOverlayClick={false}
			destroyOnClose={true}
		>
			{xellarPopupConfirmationType === 'allowance' && (
				<CardAllowance
					loading={loading}
					onReject={onReject}
					onConfirm={onConfirm}
					dataEstimateGas={dataEstimateGas}
				/>
			)}
			{xellarPopupConfirmationType === 'transaction' && (
				<CardTransaction
					loading={loading}
					onReject={onReject}
					onConfirm={onConfirm}
					dataEstimateGas={dataEstimateGas}
				/>
			)}
		</ModalC>
	);
};

export default PopupConfirmation;
