export type ChainType = 'eth_mainnet' | 'bsc_mainnet';
export type MenuOptionDestinationType = {
	id: ChainType;
	chain_id: number;
	name: string;
	img: string;
	network_name: string;
	img_network: string;
};

export const MENU_NETWORK_OPTION: MenuOptionDestinationType[] = [
	{
		id: 'bsc_mainnet',
		chain_id: 56,
		name: 'BSC',
		img: '/icons/bsc.svg',
		network_name: 'Binance Smart Chain',
		img_network: '/icons/bnb-network.svg',
	},
	{
		id: 'eth_mainnet',
		chain_id: 1,
		name: 'Ethereum',
		img: '/icons/eth.svg',
		network_name: 'Ethereum',
		img_network: '/icons/eth-network.svg',
	},
];
