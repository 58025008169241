import { ChainType } from 'src/constants/network';
import { useWeb3Context } from 'src/context/web3';
import { TypeAny } from 'src/interface';
import { notify } from 'src/utils/notify';
import web3config from 'src/utils/web3config';

import useXellarReducer from '../reducers/useXellarReducer';

const TEXT_USER_REJECT = 'User does not approve';

const useWeb3Helper = () => {
	const { isXellar, getXellarContract, address, getContract, creoBalance }: any = useWeb3Context();

	const { xellarSendTransaction } = useXellarReducer();

	const web3Approval = async (targetAddress: string, priceItem: TypeAny, chain?: ChainType) => {
		try {
			const { creoAbi, creoAddress, bridgeAbiAndAddress } = web3config;

			const config = {
				abi: chain ? JSON.stringify(bridgeAbiAndAddress[chain].token.abi) : creoAbi,
				address: chain ? bridgeAbiAndAddress[chain].token.address : creoAddress,
			};

			if (isXellar) {
				const creoContract = await getXellarContract(config.abi, config.address);

				const allowance = await creoContract.allowance(address, targetAddress);

				const balance = creoBalance * 10 ** 18;
				// if (BigInt(allowance) < BigInt(priceItem)) {
				if (BigInt(allowance) < BigInt(balance)) {
					// approval check
					const approval: any = await xellarSendTransaction(
						config.address,
						config.abi,
						address,
						'approve',
						targetAddress,
						balance.toString(),
					);

					if (!approval) {
						throw new Error('error');
					} else if (approval?.status === false) {
						throw new Error(TEXT_USER_REJECT);
					}

					return true;
				}

				return false;
			} else {
				const creoContract = await getContract(config.abi, config.address);

				const allowance = await creoContract.methods.allowance(address, targetAddress).call();

				if (BigInt(allowance) < BigInt(priceItem)) {
					// approval check
					const approval = await creoContract.methods
						.approve(targetAddress, priceItem)
						.send({ from: address });

					if (!approval) {
						throw new Error(TEXT_USER_REJECT);
					}

					return true;
				}

				return false;
			}
		} catch (error: TypeAny) {
			notify(
				error?.message === TEXT_USER_REJECT
					? TEXT_USER_REJECT
					: 'There was an error, failed to set allowance',
				'error',
			);

			throw error;
		}
	};

	return {
		web3Approval,
	};
};

export default useWeb3Helper;
